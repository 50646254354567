import React, { useCallback, useMemo, useState } from 'react';
import { PlayButton as RobloxPlayButton } from 'Roblox';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import EventListItem from './EventListItem';
import { translation } from '../translation.config';
import { tryParseDate } from '../utils/utils';
import { GameDetails, VirtualEvent } from '../services/services';
import { getTranslationStringForKeyWithFallback } from '../constants/constants';

import '../../../../css/gameDetailsVirtualEvents/eventListContainer.scss';

const { usePlayabilityStatus } = RobloxPlayButton;

type EventList = VirtualEvent[];

type EventsListContainerProps = {
  eventList: EventList;
  universeDetails: GameDetails;
} & WithTranslationsProps;

const EventsListContainer = ({
  eventList,
  universeDetails,
  translate
}: EventsListContainerProps): JSX.Element => {
  const defaultItems = 2;
  const itemsPerExpansion = 2;
  const [expansions, setExpansions] = useState(0);
  const [playabilityStatus, refetchPlayabilityStatus] = usePlayabilityStatus(
    universeDetails.id.toString()
  );

  const seeMoreBtnClicked = useCallback(() => {
    setExpansions((currentExpansions: number) => {
      return currentExpansions + 1;
    });
  }, []);

  const filteredEventList = useMemo(() => {
    return eventList.filter(e => tryParseDate(e.eventTime.endUtc) > new Date().getTime());
  }, [eventList]);

  const isSeeMoreBtnVisible = useMemo(() => {
    return filteredEventList.length > defaultItems + itemsPerExpansion * expansions;
  }, [expansions, filteredEventList]);

  if (filteredEventList.length <= 0) {
    return <div />;
  }

  return (
    <React.Fragment>
      <div className='container-header'>
        <h2>{getTranslationStringForKeyWithFallback(translate, 'eventsTitle')}</h2>
      </div>
      <div className='stack'>
        <ul className='stack-list'>
          {filteredEventList
            .sort((a, b) =>
              tryParseDate(a.eventTime.startUtc) < tryParseDate(b.eventTime.startUtc) ? -1 : 1
            )
            .slice(0, defaultItems + itemsPerExpansion * expansions)
            .map((e: VirtualEvent) => {
              return (
                <EventListItem
                  key={e.id}
                  eventItem={e}
                  universeDetails={universeDetails}
                  playabilityStatus={playabilityStatus}
                />
              );
            })}
        </ul>
      </div>
      {isSeeMoreBtnVisible && (
        <button
          type='button'
          aria-label={getTranslationStringForKeyWithFallback(translate, 'seeMore')}
          onClick={seeMoreBtnClicked}
          className='notify-button btn-full-width btn-control-md'>
          {getTranslationStringForKeyWithFallback(translate, 'seeMore')}
        </button>
      )}
    </React.Fragment>
  );
};

export default withTranslations(EventsListContainer, translation);
