import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ExperimentationService, CurrentUser } from 'Roblox';
import {
  getGamePlaceDetails,
  getEventsForUniverseId,
  getDetailsForUniverseIds
} from './services/services';

import EventsListContainer from './components/EventsListContainer';

const Roblox = window.Roblox || {};

function GameDetailsVirtualEventsSectionRoot() {
  const placeId = Roblox.GamePassJSData.PlaceID;
  const [experiment, setExperiment] = useState(null);
  const [eventsDetails, setEventsDetails] = useState([]);
  const [universeDetails, setUniverseDetails] = useState({});
  const [experimentExposureLogged, setExperimentExposureLogged] = useState(false);
  const elementRef = useRef(null);

  const experimentPropertyName = 'virtualEventsMVPEnabled';
  const experimentLayerName = 'CreatorSuccess.VirtualEvents';

  // fire this to mark that user was enrolled in the experiment
  const logExperimentEnrollment = useCallback(() => {
    ExperimentationService.logLayerExposure(experimentLayerName);
    setExperimentExposureLogged(true);
  }, []);

  // setup scroll observer for analytics tracking
  useEffect(() => {
    // don't set up observer for unauthenticated users
    if (!CurrentUser.isAuthenticated) {
      return;
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3
    };

    const eRef = elementRef.current;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (eventsDetails.length > 0 && !experimentExposureLogged) {
          logExperimentEnrollment();
        }
      }
    }, options);

    if (eRef) {
      observer.observe(eRef);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (eRef) {
        observer.unobserve(eRef);
      }
    };
  }, [elementRef, eventsDetails, experimentExposureLogged, logExperimentEnrollment]);

  useEffect(() => {
    const getExperimentShouldSeeEvents = async () => {
      const res = await ExperimentationService.getAllValuesForLayer(experimentLayerName);
      return res[experimentPropertyName] === true;
    };

    const fetchData = async () => {
      // TODO https://jira.rbx.com/browse/EN-1848 only run this check if we're logged in since this api needs creds
      const gameDetails = await getGamePlaceDetails(placeId);
      const eventsDetailsRes = await getEventsForUniverseId(gameDetails?.universeId);
      if (eventsDetailsRes?.length > 0) {
        const universeDetailsRes = await getDetailsForUniverseIds([gameDetails?.universeId]);
        setUniverseDetails(universeDetailsRes);
      }
      setEventsDetails(eventsDetailsRes);
    };

    const initialSetup = async () => {
      // don't load anything for unauthenticated users
      if (!CurrentUser.isAuthenticated) {
        return;
      }
      const experimentRes = await getExperimentShouldSeeEvents();
      setExperiment(experimentRes);
      fetchData();
    };

    initialSetup();
  }, [placeId]);

  if (eventsDetails?.length > 0) {
    return (
      <div ref={elementRef} className='virtual-event-game-details-container'>
        {experiment === true && (
          <EventsListContainer eventList={eventsDetails} universeDetails={universeDetails} />
        )}
      </div>
    );
  }
  return <div />;
}

export default GameDetailsVirtualEventsSectionRoot;
