import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import GameDetailsVirtualEventsSectionRoot from './GameDetailsVirtualEventsSectionRoot';

const containerId = 'game-events-container';

ready(() => {
  if (document.getElementById(containerId)) {
    render(<GameDetailsVirtualEventsSectionRoot />, document.getElementById(containerId));
  }
});
