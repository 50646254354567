import Roblox from 'Roblox';

const ctx = 'virtualEvents';

const CHANNELS = {
  experienceDetailsPage: 'expDetailsPage'
};

const EVENT_NAMES = {
  virtualEventJoined: 'virtualEventJoined',
  eventDetailsPageVisit: 'eventDetailsPageVisit',
  virtualEventRSVP: 'virtualEventRSVP',
  virtualEventImpression: 'virtualEventImpression'
};

export const getGameLaunchEventStreamProperties = (
  eventJoinSessionId: string
): { eventName: string; ctx: string; eventJoinSessionId: string } => {
  return {
    eventName: 'virtualEventJoinGame',
    ctx,
    eventJoinSessionId
  };
};

const AnalyticsEvents = {
  sendVirtualEventImpressionFromExperienceDetailsPageEvent: (
    eventId: string,
    universeId: number
  ): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.virtualEventImpression,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        channel: CHANNELS.experienceDetailsPage
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  },
  sendVirtualEventJoinedFromExperienceDetailsPageEvent: (
    eventId: string,
    universeId: number,
    sessionId: string,
    attendanceCount: number
  ): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.virtualEventJoined,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        'SD.gamePlayFromEventDetails.ID': sessionId,
        'SD.gamePlayFromEventDetails.CT': Date.now(),
        channel: CHANNELS.experienceDetailsPage,
        attendanceCount
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  },
  sendEventDetailsPageVisitFromExpDetailsEvent: (eventId: string, universeId: number): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.eventDetailsPageVisit,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        channel: CHANNELS.experienceDetailsPage
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  },
  sendVirtualEventRSVPFromExpDetailsEvent: (
    eventId: string,
    universeId: number,
    status: string,
    attendanceCount: number
  ): void => {
    Roblox.EventStream.SendEventWithTarget(
      EVENT_NAMES.virtualEventRSVP,
      ctx,
      {
        virtualEventId: eventId,
        universeId,
        channel: CHANNELS.experienceDetailsPage,
        status,
        attendanceCount
      },
      Roblox.EventStream.TargetTypes.WWW
    );
  }
};

export default AnalyticsEvents;
